import React, { useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase.js';
import '../../css/App.css';

const FirstTimeClassroomSetup = ({ user, onClose, onClassroomSetup }) => {
    const [gradeLevel, setGradeLevel] = useState("");
    const [classProgression, setClassProgression] = useState("");
    const [classPrivacy, setClassPrivacy] = useState("");
    const [requiredPassesToProgress, setRequiredPassesToProgress] = useState("");

    const handleSubmit = async () => {
        const userID = user.uid;
        const userData = {
            gradeLevel,
            classProgression,
            classPrivacy,
            requiredPassesToProgress: Number(requiredPassesToProgress),
            classConfigured: true,
        };

        const userDocRef = doc(firestore, 'users', userID);
        await updateDoc(userDocRef, userData);

        // Simulating an update action
        console.log('Updating user data:', userData);

        onClassroomSetup();
    };

    return (
            <div className="modal-content">

                <div className='inputRow'>
                    <div className='inputContainer'>
                        <label htmlFor='gradeLevel'>Grade Level</label>
                        <select id='gradeLevel' value={gradeLevel} onChange={(event) => setGradeLevel(event.target.value)}>
                            <option value=''>Select Grade Level</option>
                            {['Homeschool', 'K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'].map(level => (
                                <option key={level} value={level}>{level}</option>
                            ))}
                        </select>
                    </div>
                
            
                    <div className='inputContainer'>
                        <label htmlFor='classProgression'>Class Progression</label>
                        <select id='classProgression' value={classProgression} onChange={(event) => setClassProgression(event.target.value)}>
                            <option value=''>Select Class Progression</option>
                            <option value='fixed'>Fixed</option>
                            <option value='free'>Free</option>
                        </select>
                    </div>
                </div>
                
                <div className='inputRow'>
                    <div className='inputContainer'>
                        <label htmlFor='classPrivacy'>Class Privacy</label>
                        <select id='classPrivacy' value={classPrivacy} onChange={(event) => setClassPrivacy(event.target.value)}>
                            <option value=''>Select Class Privacy</option>
                            <option value='public'>Public</option>
                            <option value='private'>Private</option>
                        </select>
                    </div>
                    
                    <div className='inputContainer'>
                        <label htmlFor='requiredPassesToProgress'>Required Passes</label>
                        <select id='requiredPassesToProgress' value={requiredPassesToProgress} onChange={(event) => setRequiredPassesToProgress(event.target.value)}>
                            <option value=''>Select Required Passes</option>
                            {[1, 2, 3, 4, 5, 6, 7, 8].map(passes => (
                                <option key={passes} value={passes}>{passes}</option>
                            ))}
                        </select>
                    </div>
                </div>
                
                <div className='setupClassroomBtnContainer'>
                    <button className='setupClassroomBtn' onClick={handleSubmit}>Setup Classroom!</button>
                </div>
            </div>
    );
}

export default FirstTimeClassroomSetup;
