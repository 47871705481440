import React from "react";
import { useLocation } from "react-router-dom";
import '../css/fullPost.css'; 

export default function FullPost() {
    const location = useLocation();
    const post = location.state?.post;

    if (!post) {
        return <p>Post not found!</p>;
    }

    return (
        <div className="fullPost">
            <div className="postInfo">
                <a href="/blog">&larr; Back to Blogs</a>
                <img src={post.imgURL} alt={post.title} />
                <p>Written By: {post.author}</p>
                <p className="topicsTitle">Topics</p>
                <p className="tags">{post.tags.join(", ")}</p>
            </div>
            <div className="postBody">
                <div>
                    <h1>{post.title}</h1>
                    <p>{post.body[0] || ''}</p>
                    <p>{post.body[1] || ''}</p>
                    <p>{post.body[2] || ''}</p>
                    <p>{post.body[3] || ''}</p>
                    <p>{post.body[4] || ''}</p>
                    <p>{post.body[5] || ''}</p>
                    <p>{post.body[6] || ''}</p>
                    <p>{post.body[7] || ''}</p>
                    <p>{post.body[8] || ''}</p>
                    <p>{post.body[9] || ''}</p>
                    <p>{post.body[10] || ''}</p>
                </div>
            </div>
        </div>
    );
}