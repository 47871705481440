import React, { useState, useEffect } from 'react';
import { createUserWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { doc, setDoc } from "firebase/firestore";
import { auth, firestore } from '../firebase.js';
import { Link, useNavigate } from 'react-router-dom';
import '../css/signup.css';

const Signup = () => {
    const [registerEmail, setRegisterEmail] = useState("");
    const [registerPassword, setRegisterPassword] = useState("");
    const [honeyPotValue, setHoneyPotValue] = useState("");
    const [user, setUser] = useState(null);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [classCode, setClassCode] = useState("");
    let classCodeLength = 5;
    const navigate = useNavigate();

    const makeid = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength)).toUpperCase();
            counter += 1;
        }
        setClassCode(result);
        return result;
    }

    useEffect(() => {
        onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
            }
        });
    }, []);

    const writeUser = async (user) => {
        const newClassCode = makeid(classCodeLength);
        const userID = user.uid;
        const userData = {
            firstName,
            lastName,
            email: registerEmail,
            classCode: newClassCode,
            gradeLevel: null,
            classProgression: null,
            classPrivacy: null,
            curriculumName: 'UFLI Foundations',
            classConfigured: false,
            paidMember: false,
        };

        await setDoc(doc(firestore, 'users', userID), userData);
    }

    const register = async () => {
        if (honeyPotValue != "") {
            alert('Error: Account could not be created.')
        } else {
            try {
                const userCredential = await createUserWithEmailAndPassword(auth, registerEmail, registerPassword);
                await writeUser(userCredential.user);
                navigate('/TeacherDash');
            } catch (error) {
                console.log(error.message);
            }
        }
    };

    const showHidePassword = () => {
        var passwordField = document.getElementById("password");
        if (passwordField.type === "password") {
            passwordField.type = "text";
        } else {
            passwordField.type = "password";
        }
      }

    const handleBack = () => {
        setFirstName("");
        setLastName("");
        setRegisterEmail("");
        setRegisterPassword("");
    }

    return (
        <div className='signupPage'>
            <div className='registerContainer'>
                <h3 className='signUpHeader'>Welcome to Babblebot!</h3>
                <div className='nameInputs'>
                    <input className='fName registerInput' placeholder='first name' onChange={(event) => setFirstName(event.target.value)} />
                    <input className='lName registerInput' placeholder='last name' onChange={(event) => setLastName(event.target.value)} />
                </div>
                <input className='email registerInput' placeholder='email' onChange={(event) => setRegisterEmail(event.target.value)} />
                <input className='password registerInput' id='password' placeholder='password' type='password' onChange={(event) => setRegisterPassword(event.target.value)} />
                <input className='registerInput' placeholder='password' type='hidden' onChange={(event) => setHoneyPotValue(event.target.value)}/>
                <div className='showPass'>
                    <input type="checkbox" onclick={(event) => showHidePassword()} />Show Password
                </div>
                <button className='registerBtn' onClick={register}>Sign Up</button>
                {/*<button className='backBtn' onClick={handleBack}>Back</button>*/}
                <div className='alreadyHaveAnAccountContainer'>
                    <p>Already have an account?</p>
                    <Link className='signUpLink' to='/signin'>Login</Link>
                </div>
            </div>
        </div>
    );
}

export default Signup;
