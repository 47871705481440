import React, { useState, useEffect } from 'react';
import { doc, getDoc, collection, getDocs, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase.js';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

import './configureClassroom.css';

library.add( faCircleInfo )

const ConfigureClassroom = ({ teacherDocumentID }) => {
    const [classProgression, setClassProgression] = useState('');
    const [gradeLevel, setGradeLevel] = useState('');
    const [classPrivacy, setClassPrivacy] = useState('');
    const [requiredPassesToProgress, setRequiredPassesToProgress] = useState(null);
    const [students, setStudents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showSettings, setShowSettings] = useState(false);

    const fetchTeacherData = async () => {
        setLoading(true);
        try {
            const teacherDocRef = doc(firestore, 'users', teacherDocumentID);
            const teacherDocSnapshot = await getDoc(teacherDocRef);
            if (teacherDocSnapshot.exists()) {
                const data = teacherDocSnapshot.data();
                setClassProgression(data.classProgression || '');
                setGradeLevel(data.gradeLevel || '');
                setClassPrivacy(data.classPrivacy || '');
                setRequiredPassesToProgress(data.requiredPassesToProgress ?? null);
            } else {
                // Handle case where the document does not exist
                setClassProgression('');
                setGradeLevel('');
                setClassPrivacy('');
                setRequiredPassesToProgress(null);
            }

            const studentsCollectionRef = collection(firestore, 'users', teacherDocumentID, 'students');
            const studentsSnapshot = await getDocs(studentsCollectionRef);
            const studentsList = studentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setStudents(studentsList);
        } catch (error) {
            console.error("Error fetching teacher data: ", error);
        } finally {
            setLoading(false);
            setShowSettings(true);
        }
    };
    

    const handleSave = async () => {
        setLoading(true);
        try {
            const teacherDocRef = doc(firestore, 'users', teacherDocumentID);
            await updateDoc(teacherDocRef, {
                classProgression,
                gradeLevel,
                classPrivacy,
                requiredPassesToProgress: requiredPassesToProgress !== null ? requiredPassesToProgress : null
            });
            setShowSettings(false);
        } catch (error) {
            console.error("Error saving changes: ", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
        <div className='settingsContainer'>
            <div className='configureClassContainer'>
                <div style={{display: 'flex', alignItems: 'center'}}>
                        <h3>Progression Type</h3>
                        <div className='tooltip'>
                            <FontAwesomeIcon icon="fa-solid fa-circle-info" style={{color: "#918d8d",height: '12px'}} />
                            <span class="tooltiptext">Limit or allow amount of lessons a day</span>
                        </div>
                </div>
                        <div className='settingDropdown'>
                            <select value={classProgression} onChange={e => setClassProgression(e.target.value)}>
                                {classProgression === '' && <option value="">{classProgression}</option>}
                                <option value="Fixed">Fixed</option>
                                <option value="Free">Free</option>
                            </select>
                        </div>
            </div>

            <hr style={{width: '100%'}}/>

            <div className='configureClassContainer'>
                <div style={{display: 'flex', alignItems: 'center'}}>
                        <h3>Grade Level</h3>
                        <div className='tooltip'>
                            <FontAwesomeIcon icon="fa-solid fa-circle-info" style={{color: "#918d8d",height: '12px'}} />
                            <span class="tooltiptext">Grade level of classroom, affects reading level</span>
                        </div>
                </div>
                    <div className='settingDropdown'>
                        <select value={gradeLevel} onChange={e => setGradeLevel(e.target.value)}>
                            {gradeLevel === '' && <option value="">{gradeLevel}</option>}
                            {['K', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 'Homeschool'].map(level => (
                                <option key={level} value={level}>{level}</option>
                            ))}
                        </select>
                    </div>
            </div>

            <hr style={{width: '100%'}}/>

            <div className='configureClassContainer'>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <h3>Classroom Privacy</h3>
                    <div className='tooltip'>
                        <FontAwesomeIcon icon="fa-solid fa-circle-info" style={{color: "#918d8d",height: '12px'}} />
                        <span class="tooltiptext">Allow or block new students from joining</span>
                    </div>
                </div>

                    <div className='settingDropdown'>
                        <select value={classPrivacy} onChange={e => setClassPrivacy(e.target.value)}>
                            {classPrivacy === '' && <option value="">{classPrivacy}</option>}
                            <option value="public">Public</option>
                            <option value="private">Private</option>
                        </select>
                    </div>
            </div>

            <hr style={{width: '100%'}}/>

            <div className='configureClassContainer'>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <h3>Progression Speed</h3>
                    <div className='tooltip'>
                        <FontAwesomeIcon icon="fa-solid fa-circle-info" style={{color: "#918d8d",height: '12px'}} />
                        <span class="tooltiptext">Required passed lessons to complete objective</span>
                    </div>
                </div>
                    <div className='settingDropdown'>
                        <select value={requiredPassesToProgress !== null ? requiredPassesToProgress : ''} onChange={e => setRequiredPassesToProgress(Number(e.target.value))}>
                            {requiredPassesToProgress === null && <option value="">{requiredPassesToProgress}</option>}
                            {[1, 2, 3, 4, 5, 6, 7, 8].map(passes => (
                                <option key={passes} value={passes}>{passes}</option>
                            ))}
                        </select>
                    </div>
            </div>
    </div>

    <button className='confirmClassBtn' onClick={handleSave}>Save Changes</button>
    </>
    );
};

export default ConfigureClassroom;
