import React, { useEffect } from "react";
import './badges.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';

library.add( faTrophy );

export default function FetchBadges(
    {precedingObjectiveData}
) {

    console.log("badges ", precedingObjectiveData)

    return (
        <div className='badgesContainer'>
            <h3>Badges</h3>
            <div>
                {/* {precedingObjectiveData.map((objective) => (
                    <div className="icon-wrapper" key={objective.phonemeFocus}>
                        <FontAwesomeIcon icon="fa-solid fa-trophy" className="trophy" />
                        <p>{objective.phonemeFocus}</p>
                    </div>
                ))} */}
            </div>
        </div>
    )
}