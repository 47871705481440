import { collection, getDocs } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { firestore } from "../../firebase";
import './tickets.css';

export default function TicketsComponent ( {teacherDocumentID} ) {

    const [ticketData, setTicketData] = useState([]);
    const [loading, setLoading] = useState(true);

    const collectTickets = async (teacherDocumentID) => {
      try {
        const ticketsCollection = collection(firestore, 'users', `${teacherDocumentID}`, 'tickets');
        const ticketsSnapshot = await getDocs(ticketsCollection);
        const ticketData = ticketsSnapshot.docs.map(doc => ({
            ...doc.data()
        }))

        if(ticketData) {
            setTicketData(ticketData);
        }
      } catch (error) {
        return (
          <>
            <p>Could not retrieve ticket information.</p>
            <p>{error}</p>
          </>
        )
      } finally {
        setLoading(false);
      }
    }

    useEffect(() => {
        if (teacherDocumentID) {
          collectTickets(teacherDocumentID);
        }
        console.log("Tickets Collected");
    }, [teacherDocumentID]); 

    const openTicket = (index) => {
      const description = document.getElementById('description' + index);
      if (description.style.display === 'none'){
        description.style.display = 'block';
      } else {
        description.style.display = 'none';
      }
    }

    const getColor = (status) => {
      if (status  === "Closed") {
          return '#44AF69';
      } else if (status == "In Progress") {
          return '#F3C03B';
      } else if (status == "Opened") {
          return '#fff';
      }
  };

    return (
        <div>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            {ticketData ? (
              <p>No Tickets Opened</p>
            ) : (
              <div className="ticketsContainer">
              {ticketData && ticketData?.map((ticket, index) => (
                <div key={index} className="ticketInfoContainer" >
                  <div className="ticketMainInfo" onClick={(e) => openTicket(index)}>
                    <p className="ticketIssue">{ticket.issue}</p>  {/* Replace with the actual property */}
                    <div className="statusContainer">
                        <p className="ticketStatus">{ticket.status}</p>
                        <div className="statusColor" style={{ backgroundColor: getColor(ticket.status)}}></div>
                    </div>
                  </div>
                  <div className="ticketDescription" id={`description${index}`} style={{display: 'none'}}>
                    <div className="ticketInfoRow">
                      <p className="ticketInfoTitle">Feature:</p>
                      <p className="">{ticket.feature}</p>
                    </div>
                    <div className="ticketInfoRow">
                      <p className="ticketInfoTitle">Description:</p>
                      <p>{ticket.description}</p></div>
                  </div>
                </div>
              ))}
            </div>
            )}
          </>
        )}
      </div>
    )
}