import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import './contactForm.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    fName: "",
    lName: "",
    email: "",
    message: "",
    feature: "",
  });
  const [status, setStatus] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
  
    console.log("Sending email with:", {
      serviceID: process.env.REACT_APP_EMAILJS_SERVICE_ID,
      templateID: process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      formData,
      publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_ID,
    });
  
    const { fName, lName, email, message, feature } = formData;
  
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        {
          from_name: fName,
          lName: lName,
          from_email: email,
          message: message,
          feature: feature,
        },
        process.env.REACT_APP_EMAILJS_PUBLIC_ID
      )
      .then(
        () => {
          setStatus("Message sent successfully!");
          setFormData({ fName: "", lName: "", email: "", message: "", feature: "" });
        },
        (error) => {
          setStatus("Failed to send message. Try again later.");
          console.error("Email send error:", error);
        }
      );
  };
  

  return (
    <div className="formContainer">
      <h2>Submit a Ticket</h2>
      <p>We are here to help. We are a two man show at Team BabbleBot, we will get to your request as soon as we can. Thank you for your patience.</p>
      <form onSubmit={handleSubmit} id="support-form" className="supportForm">
        <div className="nameAndEmailContainer">
            <input
            type="text"
            name="fName"
            placeholder="First Name"
            value={formData.fName}
            onChange={handleChange}
            className="supportFormInput"
            required
            />
            <input
            type="text"
            name="lName"
            placeholder="Last Name"
            value={formData.lName}
            onChange={handleChange}
            className="supportFormInput"
            required
            />
        </div>
        <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            className="supportFormInput"
            required
        />
        <input
          type="text"
          name="feature"
          placeholder="Which feature is this about?"
          value={formData.feature}
          onChange={handleChange}
          className="supportFormInput"
          required
        />
        <textarea
          name="message"
          placeholder="Explain the occuring issue..."
          value={formData.message}
          onChange={handleChange}
          className="supportFormInput supportTextArea"
          required
        ></textarea>
        <button type="submit" className="formSubmitButton">
          Submit Ticket
        </button>
        {status && <p className="statusMessage">{status}</p>}
      </form>
    </div>
  );
};

export default ContactForm;
