import React, { useState, useEffect } from 'react';
import { firestore } from "../../firebase";
import { collection, doc, updateDoc, deleteDoc, onSnapshot, query, addDoc } from "firebase/firestore";
import ClassUpload from '../firebase/classUpload';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faUserMinus, faUserPlus, faFileMedical } from '@fortawesome/free-solid-svg-icons';

import './classRoster.css';

library.add( faPencil, faUserMinus, faUserPlus, faFileMedical );

const ClassRoster = ({ teacherDocumentID }) => {
    const [studentListData, setStudentListData] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [currentObjective, setCurrentObjective] = useState('');
    const [showAddStudentForm, setShowAddStudentForm] = useState(false);

    useEffect(() => {
        const unsubscribe = fetchStudentsData();

        return () => {
            unsubscribe();
        };
    }, []);

    const fetchStudentsData = () => {
        const studentsCollectionRef = collection(firestore, 'users', teacherDocumentID, 'students');
        const q = query(studentsCollectionRef);

        return onSnapshot(q, (snapshot) => {
            const studentsData = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                editing: false, // Add editing flag for each student
                originalData: { // Store original data for canceling edits
                    firstName: doc.data().firstName,
                    lastName: doc.data().lastName,
                    currentObjective: doc.data().currentObjective
                }
            }));
            const sortedStudents = studentsData.sort((a, b) => a.firstName.localeCompare(b.firstName));
            setStudentListData(sortedStudents);
        }, (error) => {
            console.error('Error fetching students:', error);
        });
    };

    const handleAddStudent = async () => {
        if (!firstName || !lastName || !currentObjective) {
            alert('Please fill in all fields.');
            return;
        }

        try {
            const studentsCollectionRef = collection(firestore, 'users', teacherDocumentID, 'students');
            await addDoc(studentsCollectionRef, {
                firstName,
                lastName,
                currentObjective: parseInt(currentObjective), // Convert setCurrentObjective to a number
                backgroundImg: '/static/media/streetWithBenchAndLights.97abc3de65b8bf1ee6a8.jpg',
                profileImg: '/static/media/BabbleHandsUp.9bb7ef5e5db62605b676.png',
            });

            setFirstName('');
            setLastName('');
            setCurrentObjective('');
            setShowAddStudentForm(false);

            // Refresh student list after adding
            fetchStudentsData();
        } catch (error) {
            console.error('Error adding student:', error);
            alert('Error adding student. Please try again.');
        }
    };

    const handleEditStudent = (studentId) => {
        setStudentListData(prevStudents => {
            return prevStudents.map(student => {
                if (student.id === studentId) {
                    return {
                        ...student,
                        editing: true
                    };
                }
                return student;
            });
        });
    };

    const handleInputChange = (event, studentId, field) => {
        const newValue = event.target.value;

        setStudentListData(prevStudents => {
            return prevStudents.map(student => {
                if (student.id === studentId) {
                    return {
                        ...student,
                        [field]: newValue
                    };
                }
                return student;
            });
        });
    };

    const handleConfirmChanges = async (studentId) => {
        try {
            const updatedData = {
                firstName: studentListData.find(student => student.id === studentId).firstName,
                lastName: studentListData.find(student => student.id === studentId).lastName,
                currentObjective: parseInt(studentListData.find(student => student.id === studentId).currentObjective)
            };
            await updateDoc(doc(firestore, 'users', teacherDocumentID, 'students', studentId), updatedData);

            setStudentListData(prevStudents => {
                return prevStudents.map(student => {
                    if (student.id === studentId) {
                        return {
                            ...student,
                            editing: false
                        };
                    }
                    return student;
                });
            });
        } catch (error) {
            console.error('Error confirming changes:', error);
        }
    };

    const handleCancelEdit = (studentId) => {
        setStudentListData(prevStudents => {
            return prevStudents.map(student => {
                if (student.id === studentId) {
                    return {
                        ...student,
                        firstName: student.originalData.firstName,
                        lastName: student.originalData.lastName,
                        currentObjective: student.originalData.currentObjective,
                        editing: false
                    };
                }
                return student;
            });
        });
    };

    const handleDeleteStudent = async (studentId) => {
        try {
            const studentDocRef = doc(firestore, 'users', teacherDocumentID, 'students', studentId);
            await deleteDoc(studentDocRef);
        } catch (error) {
            console.error('Error deleting student:', error);
        }
    };

    return (
        <div className="classRosterContainer">
            <div className='headerAndButtons'>
                <h2>Class Roster</h2>
                <div>
                    <button className='headerButtons'><FontAwesomeIcon className='buttonIcon' icon="fa-solid fa-file-medical" />Upload Roster</button>
                    <button onClick={() => setShowAddStudentForm(true)} className='headerButtons'><FontAwesomeIcon className='buttonIcon' icon="fa-solid fa-user-plus" />Add Student</button>
                </div>
            </div>
            {showAddStudentForm && (
                <div className='addStudentForm'>
                    <input
                        className='newStudentInput'
                        type="text"
                        placeholder="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                    <input
                        className='newStudentInput'
                        type="text"
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                    <input
                        className='newStudentInput'
                        type="number"
                        placeholder="Current Objective"
                        value={currentObjective}
                        onChange={(e) => setCurrentObjective(e.target.value)}
                    />
                    <button className='confirmAddStudentBtn' onClick={handleAddStudent}>Submit</button>
                </div>
            )}
            <ul className='roster'>
                {studentListData.map((student) => (
                    <li key={student.id} className='individualStudent'>
                        {student.editing ? (
                            <div className='editing'>
                                <form>
                                    <label>First Name:</label>
                                    <input
                                        type="text"
                                        value={student.firstName}
                                        onChange={(e) => handleInputChange(e, student.id, 'firstName')}
                                    />
                                    <label>Last Name:</label>
                                    <input
                                        type="text"
                                        value={student.lastName}
                                        onChange={(e) => handleInputChange(e, student.id, 'lastName')}
                                    />
                                    <label>Objective:</label>
                                    <input
                                        type="text"
                                        value={student.currentObjective}
                                        onChange={(e) => handleInputChange(e, student.id, 'currentObjective')}
                                    />
                                </form>
                                <div className='individualStudentButtons'>
                                    <button className='editConfirmBtn' onClick={() => handleConfirmChanges(student.id)}>Confirm</button>
                                    <button className='editCancelBtn' onClick={() => handleCancelEdit(student.id)}>Cancel</button>
                                </div>
                            </div>
                        ) : (
                            <div className='notEditing'>
                                <p>{student.firstName} {student.lastName}</p> 
                                {/*<p>Objective: {student.currentObjective}</p>*/}
                                <div>
                                    <button className='studentBtn' onClick={() => handleEditStudent(student.id)}><FontAwesomeIcon className='studentBtnIcon' icon="fa-solid fa-pencil" /></button>
                                    <button className='studentBtn' onClick={() => handleDeleteStudent(student.id)}><FontAwesomeIcon className='studentBtnIcon' icon="fa-solid fa-user-minus" /></button>
                                </div>
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ClassRoster;
