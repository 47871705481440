import React from "react";
import { useParams } from "react-router-dom";
import Sidebar from "../components/teacher/sideBar";
import TicketsComponent from "../components/teacher/tickets";
import '../components/teacher/tickets.css';


export default function Tickets () {

    const { firstName, lastName, teacherDocumentID, classCode } = useParams();
    

    return (
        <div style={{height: '90vh', backgroundColor: 'beige', display: 'flex'}}>
        <Sidebar firstName={firstName} lastName={lastName} teacherDocumentID={teacherDocumentID} classCode={classCode}/>
        <div className="ticketsPage">
            <h2 className="pageHeading">My Tickets</h2>
            <div>
                <TicketsComponent teacherDocumentID={teacherDocumentID} />
            </div>
        </div>
    </div>
    )
}