import React from 'react';
import LessonDetailsDisplay from './LessonDetailsDisplay';
import PassFailButtons from './PassFailButtons';
import PrevNextLessonButtons from './PrevNextLessonButtons';
import './studentPopup.css';
import AudioPlayer from './AudioPlayer';
import EditSaveButtons from './EditSaveButtons';
import ClearErroredWordsButton from './ClearErroredWordsButton';

const StudentPopup = ({
    selectedStudent,
    lessonObjectiveData,
    fetchLessonData,
    setSelectedObjective,
    handleClosePopup,
    lessonsData,
    graded,
    setGraded,
    nextLesson,
    previousLesson,
    currentLessonIndex,
    displayLesson,
    currentObjective,
    notes,
    setNotes,
    erroredWords,
    setErroredWords,
    isEditing,
    setIsEditing,
    handleSaveLessonChanges,
    handleSaveLessonChangesClick,
    handleClearErroredWordsClick,
    percentageCorrect,
    setPercentageCorrect
}) => {
    console.log(selectedStudent.prevSignOn.toDate())
    let lastSeen = selectedStudent.prevSignOn.toDate() || <p>No Last Login Data</p>;

    return (
        <div className="studentPopupContainer">
            <span className="close" onClick={handleClosePopup}>&times;</span>
            <div className="studentPopupContent">
                <h2>{`${selectedStudent.firstName} ${selectedStudent.lastName}`}</h2>
                <p>Current Objective: {currentObjective}</p>
                <p>Last Seen: {lastSeen.toLocaleString() || 'No Last Login Data'}</p>
            </div>
            <div className='objectiveContentContainer'>
                {/* Objective selection buttons */}
                <div className='objectiveButtonContainer'>
                    {lessonObjectiveData.map(objective => (
                        <React.Fragment key={objective.objectiveNum}>
                            <button className='objectiveButton' onClick={() => {
                                fetchLessonData(objective.objectiveNum, selectedStudent.id);
                                setSelectedObjective(objective.objectiveNum); // Corrected state update
                            }} key={objective.objectiveNum}>
                                Objective {objective.objectiveNum}
                            </button>
                        </React.Fragment>
                    ))}
                </div>
                <hr />
                {/* Lesson details */}
                <div className='dataContainer'>
                    {lessonsData.length > 0 && (
                        <LessonDetailsDisplay 
                            graded={graded}
                            setGraded={setGraded}
                            lesson={lessonsData[currentLessonIndex]}
                            notes={notes}
                            setNotes={setNotes}
                            erroredWords={erroredWords}
                            setErroredWords={setErroredWords}
                            isEditing={isEditing}
                            percentageCorrect={percentageCorrect}
                            setPercentageCorrect={setPercentageCorrect}
                        />
                    )}
                    <div className='lessonDisplayBottomBtns'>
                    <EditSaveButtons
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        handleSaveLessonChanges={handleSaveLessonChanges}
                        handleSaveLessonChangesClick={handleSaveLessonChangesClick}
                    />
                    <ClearErroredWordsButton
                        isEditing={isEditing}
                        handleClearErroredWordsClick={handleClearErroredWordsClick}
                    />    
                    <PrevNextLessonButtons
                        lessonsData={lessonsData}
                        currentLessonIndex={currentLessonIndex}
                        displayLesson={displayLesson}
                        nextLesson={nextLesson}
                        previousLesson={previousLesson}
                        setIsEditing={setIsEditing}
                    />
                    </div>
                    
                </div>
            </div>
        </div>
    );
};

export default StudentPopup;
