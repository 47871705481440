import React from "react";
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale, // x-axis
    LinearScale, // y-axis
    Filler
} from 'chart.js';
import './classStats.css';

import {Doughnut, Line, Bar} from 'react-chartjs-2';

ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
    Filler
);



export default function ClassStats ({
    classObjectivesKeys,
    classObjectivesValues,
    erroredWordsArray
}) {

    const countWords = (erroredWordsArray) => {
        return erroredWordsArray.reduce((count, word) => {
            count[word] = (count[word] || 0) + 1;
            return count;
        }, {});
    };

    const wordCounts = countWords(erroredWordsArray);

    const wordCountArray = Object.entries(wordCounts)
        .map(([word, count]) => ({ word, count })).sort((a, b) => b.count - a.count)

    const labels = wordCountArray.map(item => item.word); // Sorted words
    const data = wordCountArray.map(item => item.count); // Sorted counts

    const doughnutChartData = {
        labels: classObjectivesKeys,
        datasets: [{
            label: `Students`,
            data: classObjectivesValues,
            backgroundColor: ['#38023B', '#BBD5ED', '#CCFFCB', '#FFD700', '#FF6347', '#9370DB', '#87CEEB', '#90EE90', '#F4A460', '#FF69B4'],
            borderColor: ['white'],
            borderWidth: 5,
        }]
    };

    const doughnutChartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                align: 'center'
            }
        },
        hoverOffset: 10,
        aspectRatio: 1,
        borderRadius: 10,
        animation: {
            animateScale: true
        }
    };

    const barData = {
        labels: labels,
        datasets: [
            {
                label: 'Errored Words',
                data: data,
                backgroundColor: '#BBD5ED',
                borderColor: 'black',
                borderWidth: 1,
                barThickness: 'flex',
                minBarLength: 2,
            }
        ]
    }

    const barOptions = {
        borderRadius: 5
    }

    return (
        <div className="classStatsContainer">
            <div className="unitChart">
                <h3>Objectives Overview</h3>
                <Doughnut
                    data = {doughnutChartData}
                    options={doughnutChartOptions}
                ></Doughnut>             
            </div>

            <div className="horizontalCards">
                <h3>Most Mispronounced Words</h3>
                <Bar
                    data = {barData}
                    options = {barOptions}
                ></Bar>
            </div>
        </div>
    )
}