import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom'
import '../../css/blog.css';


export default function Post({ post: {title, body, img, author, tags },  index, handleBlogSelect }) {
   
    const post ={
        title: title,
        body: body,
        imgURL: img,
        author: author,
        tags: tags
    }

    return (
        <div className="post">
            <Link style={{textDecoration: 'none', color: '#000'}} to={`/blog/full-post/${title}`} state={{ post }}>
                <div className="postImg" style={{backgroundImage: `url(${img})`}}>

                </div>
                <div className="postDetails">
                    <h2 className="postTitle">{title}</h2>
                    <p className="postBody">{body}</p>
                    <p className="postAuthor">Written By: {author}</p>
                    <div className="tagsContainer">
                        {tags.map((tag, index) => (
                            <p key={index}>{tag}</p>
                        ))}
                    </div>
                </div>
            </Link>
        </div>
    )
}