import React from "react";
import '../css/plans.css';

export default function Plans() {
    return (
        <div className="plansPage">
            <div className="plansHeader">
                <h2>Choose Your Plan</h2>
            </div>
            <div className="plansContainer">
                <div className="plan free">
                    <h3>Microbot</h3>
                    <p className="maxStudents"><span>1</span> Student</p>
                    <div className="price">
                        <p><b>Free</b> /m</p>
                    </div>
                    <button>Start With This Plan</button>
                    <p className="planIncludes">This Plan Includes:</p>
                    <ul>
                        <li>AI Generated Stories</li>
                        <li>2 Story Limit/day</li>
                        <li>1 Standard Curriculum</li>
                        <li>Story Audio and Grading</li>
                    </ul>                
                </div>
                <div className="plan smallLecture">
                    <h3>Workhorse</h3>
                    <p className="maxStudents"><span>10</span> Students</p>
                    <div className="price">
                        <p><b>$100</b> /m</p>
                    </div>
                    <button>Start With This Plan</button>
                    <p className="planIncludes">This Plan Includes:</p>
                    <ul>
                        <li>AI Generated Stories</li>
                        <li>5 Story Limit/day</li>
                        <li>Personal Story Library</li>
                        <li>2 Standard Curricula</li>
                        <li>Story Audio and Grading</li>
                        <li>Class Level Analytics</li>
                        <li>Word Level Analytics</li>
                    </ul>    
                </div>
                <div className="plan classroom">
                    <h3>Mecha</h3>
                    <p className="maxStudents"><span>Unlimited</span> Students</p>
                    <div className="price">
                        <p><b>Contact Us</b></p>
                    </div>
                    <button>Start With This Plan</button>
                    <p className="planIncludes">This Plan Includes:</p>
                    <ul>
                        <li>AI Generated Stories</li>
                        <li>Unlimited Stories/day</li>
                        <li>Personal Story Library</li>
                        <li>5 Standard Curricula</li>
                        <li>Story Audio and Grading</li>
                        <li>Class Level Analytics</li>
                        <li>Word Level Analytics</li>
                        <li>Advanced Student Analytics</li>
                        <li>Curriculum Creator</li>
                    </ul>    
                </div>
            </div>
        </div>
    )
}