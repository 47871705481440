import React from "react";
import { useParams } from "react-router-dom";
import Sidebar from "../components/teacher/sideBar";
import ContactForm from "../components/teacher/contactForm";
import '../components/teacher/contactForm.css';

export default function Support () {

    const { firstName, lastName, teacherDocumentID, classCode } = useParams();
    
    return (
        <div style={{height: '90vh', backgroundColor: 'beige', display: 'flex'}}>
        <Sidebar firstName={firstName} lastName={lastName} teacherDocumentID={teacherDocumentID} classCode={classCode}/>
        <div className="supportPage">
            <div>
                <ContactForm />
            </div>
        </div>
    </div>
    )
}